const visitedStatesLists = {
    Chandler: [
        'AK',
        'AL',
        'AR',
        'AZ',
        // 'CA',
        'CO',
        // 'CT',
        'DE',
        'FL',
        'GA',
        // 'HI',
        'IA',
        // 'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        // 'NJ',
        'NM',
        // 'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        // 'SC',
        'SD',
        'TN',
        'TX',
        // 'UT',
        'VA',
        'VT',
        'WA',
        'WI',
        // 'WV',
        'WY',
    ],
    Käthe: [
        'AL',
        'AR',
        // 'AK',
        'AZ',
        'CA',
        'CO',
        // 'CT',
        'DE',
        'FL',
        'GA',
        'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        'KS',
        'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        // 'NH',
        // 'NJ',
        // 'NM',
        'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        // 'RI',
        // 'SC',
        'SD',
        'TN',
        'TX',
        // 'UT',
        'VA',
        // 'VT',
        'WA',
        // 'WV',
        'WI',
        'WY',
    ],
    Eric: [
        'AZ',
        'CO',
        'FL',
        'IA',
        'KS',
        'MN',
        'MO',
        'ND',
        'NE',
        'NM',
        'SD',
        'WI',
    ],
    Isaac: [
        'AK',
        // 'AL',
        'AR',
        'AZ',
        // 'CA',
        'CO',
        // 'CT',
        'DE',
        'FL',
        // 'GA',
        // 'HI',
        'IA',
        // 'ID',
        'IL',
        'IN',
        'KS',
        // 'KY',
        'LA',
        'MA',
        'MD',
        'ME',
        'MI',
        'MN',
        'MO',
        // 'MS',
        'MT',
        'NC',
        'ND',
        'NE',
        'NH',
        // 'NJ',
        'NM',
        // 'NV',
        'NY',
        'OH',
        'OK',
        'OR',
        'PA',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        // 'VA',
        'VT',
        'WA',
        'WI',
        // 'WV',
        'WY',
    ],
    Tony: [
        // 'AK',
        // 'AL',
        'AR',
        // 'AZ',
        // 'CA',
        'CO',
        // 'CT',
        // 'DE',
        'FL',
        // 'GA',
        // 'HI',
        'IA',
        'ID',
        'IL',
        'IN',
        // 'KS',
        'KY',
        // 'LA',
        // 'MA',
        // 'MD',
        // 'ME',
        'MI',
        'MN',
        'MO',
        'MS',
        // 'MT',
        // 'NC',
        'ND',
        'NE',
        // 'NH',
        // 'NJ',
        // 'NM',
        'NV',
        // 'NY',
        'OH',
        // 'OK',
        // 'OR',
        // 'PA',
        // 'RI',
        // 'SC',
        'SD',
        'TN',
        // 'TX',
        'UT',
        // 'VA',
        // 'VT',
        // 'WA',
        'WI',
        // 'WV',
        'WY',
    ],

}

export default visitedStatesLists;
