import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import pinURL from './pin.svg?url';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

const vectorLayer = new VectorLayer({
    source: new VectorSource({
      loader: async function() {
        const res = await fetch("https://api-v3.amtraker.com/v3/trains");
        const trainsByRoute = await res.json();

        for (let [_route, trains] of Object.entries(trainsByRoute)) {
          for (let train of trains) {
            const {stations: _, ...trainWithoutStations} = train;
            this.addFeature(new Feature({
              geometry: new Point(fromLonLat([train.lon, train.lat])),
              ...trainWithoutStations,
            }))
          }
        }
      },
    }),
    style: function(feature){
      let scale = [1, 1];
      let rotation = ['E', 'SE', 'S', 'SW', 'W', 'NW', 'N', 'NE'].indexOf(feature.get('heading'));
      if (rotation < 0) { // heading not found in array
        console.warn(`Could not find ${feature.get('heading')} in rotation array`);
      }
      if (2 < rotation && rotation <= 6 ) {
        rotation -= 4; // half turn
        scale = [-1, 1];
      }
      rotation = Math.PI / 4 * rotation;
      return new Style({
        image: new Icon({
            anchor: [0.5, .9],
            src: pinURL,
            rotateWithView: true,
            rotation: rotation,
            scale: scale,
        }),
      });
    }
});

setInterval(() => vectorLayer.getSource().refresh(), 30000);

export default vectorLayer;
