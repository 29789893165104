import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import pin from './pin.svg?url';
import states from './states.js';

let survey_markers = {
    name: "National Geodetic Survey Markers",
    details: `These are extremely large, and loading too many at once may crash your browser.<br><a href="#" onclick="this.closest('details').querySelectorAll('ul li input').forEach(e => e.checked || e.click()); return false;">Enable All (will <i>definitely</i> crash your browser)</a>`,
    layers: [],
};

for (let [state, url] of Object.entries(states)) {
    const vectorLayer = new VectorLayer({
        source: new VectorSource({
            url: url,
            format: new GeoJSON,
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: pin,
            }),
        }),
    });

    survey_markers.layers.push({
        name: state,
        layer: vectorLayer,
    });
}

survey_markers.layers.sort((a, b) => a.name > b.name ? 1 : -1); // Names are always unique

export default survey_markers;
