import GeoJSON from 'ol/format/GeoJSON.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';

import highResStates from './us-states-500k.geojson?url';
import medResStates from './us-states-5m.geojson?url';
import lowResStates from './us-states-20m.geojson?url';

import { Fill, Stroke, Style, Text } from 'ol/style.js';

function style(feature){
    return new Style({
        text: new Text({
            text: feature.get('NAME'),
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.4)',
        }),
        stroke: new Stroke({
            color: '#3399CC',
            width: 1.25,
        }),
    });
}

const layers = {
    name: "US States",
    layers: [
        {
            name: "All (high-res)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: highResStates,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
        {
            name: "All (medium-res)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: medResStates,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
        {
            name: "All (low-res)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: lowResStates,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
    ],
};

import visitedStatesLists from './visited.js';

for (let [visitor, visitedStates] of Object.entries(visitedStatesLists)) {
    layers.layers.push({
        name: "Visited by " + visitor,
        layer: new VectorLayer({
            source: new VectorSource({
                url: lowResStates,
                format: new GeoJSON(),
            }),
            style: function(feature){
                return new Style({
                    text: new Text({
                        text: feature.get('NAME'),
                    }),
                    fill: new Fill({
                        color: visitedStates.includes(feature.get('STUSPS')) ? 'rgba(128,255,128,0.4)' : 'rgba(255,127,127,0.4)',
                    }),
                    stroke: new Stroke({
                        color: '#3399CC',
                        width: 1.25,
                    }),
                });
            }
        }),
    })
}

export default layers;
