import _ak from './data/ak.geojson?url';
import _al from './data/al.geojson?url';
import _ar from './data/ar.geojson?url';
import _as from './data/as.geojson?url';
import _az from './data/az.geojson?url';
import _bq from './data/bq.geojson?url';
import _ca from './data/ca.geojson?url';
import _co from './data/co.geojson?url';
import _cq from './data/cq.geojson?url';
import _ct from './data/ct.geojson?url';
import _dc from './data/dc.geojson?url';
import _de from './data/de.geojson?url';
import _fl from './data/fl.geojson?url';
import _fm from './data/fm.geojson?url';
import _ga from './data/ga.geojson?url';
import _gu from './data/gu.geojson?url';
import _hi from './data/hi.geojson?url';
import _ia from './data/ia.geojson?url';
import _id from './data/id.geojson?url';
import _il from './data/il.geojson?url';
import _in from './data/in.geojson?url';
import _jq from './data/jq.geojson?url';
import _ks from './data/ks.geojson?url';
import _ky from './data/ky.geojson?url';
import _la from './data/la.geojson?url';
import _ma from './data/ma.geojson?url';
import _md from './data/md.geojson?url';
import _me from './data/me.geojson?url';
import _mi from './data/mi.geojson?url';
import _ml from './data/ml.geojson?url';
import _mn from './data/mn.geojson?url';
import _mo from './data/mo.geojson?url';
import _mq from './data/mq.geojson?url';
import _ms from './data/ms.geojson?url';
import _mt from './data/mt.geojson?url';
import _nc from './data/nc.geojson?url';
import _nd from './data/nd.geojson?url';
import _ne from './data/ne.geojson?url';
import _nh from './data/nh.geojson?url';
import _nj from './data/nj.geojson?url';
import _nm from './data/nm.geojson?url';
import _nv from './data/nv.geojson?url';
import _ny from './data/ny.geojson?url';
import _oh from './data/oh.geojson?url';
import _ok from './data/ok.geojson?url';
import _or from './data/or.geojson?url';
import _pa from './data/pa.geojson?url';
import _pr from './data/pr.geojson?url';
import _pw from './data/pw.geojson?url';
import _ri from './data/ri.geojson?url';
import _sc from './data/sc.geojson?url';
import _sd from './data/sd.geojson?url';
import _tn from './data/tn.geojson?url';
import _tx from './data/tx.geojson?url';
import _ut from './data/ut.geojson?url';
import _va from './data/va.geojson?url';
import _vq from './data/vq.geojson?url';
import _vt from './data/vt.geojson?url';
import _wa from './data/wa.geojson?url';
import _wi from './data/wi.geojson?url';
import _wq from './data/wq.geojson?url';
import _wv from './data/wv.geojson?url';
import _wy from './data/wy.geojson?url';

export default {
    ak: _ak,
    al: _al,
    ar: _ar,
    as: _as,
    az: _az,
    bq: _bq,
    ca: _ca,
    co: _co,
    cq: _cq,
    ct: _ct,
    dc: _dc,
    de: _de,
    fl: _fl,
    fm: _fm,
    ga: _ga,
    gu: _gu,
    hi: _hi,
    ia: _ia,
    id: _id,
    il: _il,
    in: _in,
    jq: _jq,
    ks: _ks,
    ky: _ky,
    la: _la,
    ma: _ma,
    md: _md,
    me: _me,
    mi: _mi,
    ml: _ml,
    mn: _mn,
    mo: _mo,
    mq: _mq,
    ms: _ms,
    mt: _mt,
    nc: _nc,
    nd: _nd,
    ne: _ne,
    nh: _nh,
    nj: _nj,
    nm: _nm,
    nv: _nv,
    ny: _ny,
    oh: _oh,
    ok: _ok,
    or: _or,
    pa: _pa,
    pr: _pr,
    pw: _pw,
    ri: _ri,
    sc: _sc,
    sd: _sd,
    tn: _tn,
    tx: _tx,
    ut: _ut,
    va: _va,
    vq: _vq,
    vt: _vt,
    wa: _wa,
    wi: _wi,
    wq: _wq,
    wv: _wv,
    wy: _wy,
};
