export default [
    {
        name: "Xcel Energy Center",
        date: new Date('2022-04-28'),
    },
    {
        name: "Enterprise Center",
        date: new Date('2023-03-15'),
    },
]
