import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import pin from './pin.svg?url';
import data from './data/chains.js';

const chains = {
    name: "TJX brands",
    details: `<a href="https://en.wikipedia.org/wiki/TJX_Companies">https://en.wikipedia.org/wiki/TJX_Companies</a>`,
    layers: [],
};

for (let [chain, url] of Object.entries(data)) {
    const vectorLayer = new VectorLayer({
        source: new VectorSource({
            url: url,
            format: new GeoJSON,
        }),
        style: new Style({
            image: new Icon({
                anchor: [0.5, 1],
                src: pin,
            }),
        }),
    });

    chains.layers.push({
        name: chain,
        layer: vectorLayer,
    });
}

chains.layers.sort((a, b) => a.name > b.name ? 1 : -1); // Names are always unique

export default chains;
