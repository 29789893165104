import GeoJSON from 'ol/format/GeoJSON.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';

import counties20m from './us-counties-20m.geojson?url';
import counties5m from './us-counties-5m.geojson?url';
import schoolDistricts from './us-school-districts.geojson?url';

import { Fill, Stroke, Style, Text } from 'ol/style.js';

function style(feature){
    return new Style({
        text: new Text({
            text: feature.get('NAME'),
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.4)',
        }),
        stroke: new Stroke({
            color: '#3399CC',
            width: 1.25,
        }),
    });
}

const layers = {
    name: "US Census Bureau Data",
    layers: [
        {
            name: "All Counties (2022; low-res)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: counties20m,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
        {
            name: "All Counties (2022; medium-res)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: counties5m,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
        {
            name: "School Districts (2022; unreasonably large)",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: schoolDistricts,
                    format: new GeoJSON(),
                    // TODO: this probably uses projection 'EPSG:4326'
                }),
                style: style,
            }),
        },
    ],
};

export default layers;
