import ncAug2023 from './trahan-north-carolina-august-2023/layer.js'

const trips = {
    name: "Trips",
    layers: [
        {
            name: "North Carolina 2023-08 with Trahans",
            layer: ncAug2023,
        },
    ],
};

export default trips;
