import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import url from './data.geojson?url'; // TODO: remove `?url`?
import pin from './pin.svg?url'; // TODO: remove `?url`?

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        url: url,
        format: new GeoJSON,
    }),
    style: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: pin,
        }),
    }),
});

export default vectorLayer;
