import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style, Stroke, Circle, Fill} from 'ol/style.js';

import url from './data.geojson?url'; // TODO: remove `?url`?

import visitedArenas from './visited.js'

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        url: url,
        format: new GeoJSON,
    }),
    // TODO: use '✓' and '✗' (or maybe '✔' and '✘') (from https://en.wikipedia.org/wiki/List_of_Unicode_characters#Dingbats)
    // TODO: popups with Arena information (name, photo, date visited, score from that day)
    style: function(feature, resolution) {
        const base_color = visitedArenas.some(a => a.name == feature.get('name')) ? '#008800' : '#FF0000';
        return new Style({
            image: new Circle({
                radius: 50/Math.pow(resolution, 1/4),
                fill: new Fill({color: base_color + '33'}),
                stroke: new Stroke({color: base_color, width: 1}),
            }),
        });
    }
});

export default vectorLayer;
