import marshalls from './marshalls.geojson?url';
import winners from './winners.geojson?url';
import homesense from './homesense.geojson?url';
import tjmaxx from './tjmaxx.geojson?url';
import homegoods from './homegoods.geojson?url';
import sierra from './sierra.geojson?url';

export default {
    "Marshalls": marshalls,
    "Winners": winners,
    "HomeSense": homesense,
    "TJ Maxx": tjmaxx,
    "HomeGoods": homegoods,
    "Sierra": sierra,
};
