import Alaska from './Alaska.geojson?url';
import Arizona from './Arizona.geojson?url';
import Connecticut from './Connecticut.geojson?url';
import Georgia from './Georgia.geojson?url';
import Idaho from './Idaho.geojson?url';
import Louisiana from './Louisiana.geojson?url';
import Nevada from './Nevada.geojson?url';
import NewEngland from './NewEngland.geojson?url';
import NewYork from './NewYork.geojson?url';
import Utah from './Utah.geojson?url';
import Wisconsin from './Wisconsin.geojson?url';

export default {
    Alaska: Alaska,
    Arizona: Arizona,
    Connecticut: Connecticut,
    Georgia: Georgia,
    Idaho: Idaho,
    Louisiana: Louisiana,
    Nevada: Nevada,
    NewEngland: NewEngland,
    NewYork: NewYork,
    Utah: Utah,
    Wisconsin: Wisconsin,
};
