// https://openlayers.org/en/latest/examples/custom-controls.html

import {Control} from 'ol/control.js';

import './controls.css'

export default class ToggleMenuControl extends Control {
    /**
     * @param {Object} [opt_options] Control options.
     */
    constructor(opt_options) {
        const options = opt_options || {};

        const button = document.createElement('button');
        button.innerHTML = '≡';

        const element = document.createElement('div');
        element.className = 'show-menu ol-unselectable ol-control';
        element.appendChild(button);

        super({
            element: element,
            target: options.target,
        });

        button.addEventListener('click', () => document.body.classList.toggle("nav-open"), false);
    }
}
