import GeoJSON from 'ol/format/GeoJSON.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';

import stateParks from './mn-state-parks.geojson?url';

import { Fill, Stroke, Style, Text } from 'ol/style.js';

// Projection stuff
import proj4 from 'proj4';
import {register} from 'ol/proj/proj4.js';
// https://epsg.io/26915.proj4js
proj4.defs("EPSG:26915","+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs +type=crs");
register(proj4);


function style(feature){
    return new Style({
        text: new Text({
            text: feature.get('AREA_NAME') + ' ' + feature.get('AREA_TYPE'),
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.4)',
        }),
        stroke: new Stroke({
            color: '#008800',
            width: 1.25,
        }),
    });
}

const layers = {
    name: "MN State Parks",
    layers: [
        {
            name: "State Parks",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: stateParks,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
    ],
};

export default layers;
