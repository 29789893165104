import GeoJSON from 'ol/format/GeoJSON.js';
import VectorLayer from 'ol/layer/Vector.js';
import VectorSource from 'ol/source/Vector.js';

import nationalParks from './nps_boundary.geojson?url';
import nationalForests from './us-national-forests.geojson?url';

import { Fill, Stroke, Style, Text } from 'ol/style.js';

function style(feature){
    return new Style({
        text: new Text({
            text: feature.get('FORESTNAME') || (feature.get('UNIT_NAME') + " National Park"),
        }),
        fill: new Fill({
            color: 'rgba(255,255,255,0.4)',
        }),
        stroke: new Stroke({
            color: '#008800',
            width: 1.25,
        }),
    });
}

const layers = {
    name: "US Public Land",
    layers: [
        {
            name: "National Parks",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: nationalParks,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
        {
            name: "National Forests",
            layer: new VectorLayer({
                source: new VectorSource({
                    url: nationalForests,
                    format: new GeoJSON(),
                }),
                style: style,
            }),
        },
    ],
};

export default layers;
