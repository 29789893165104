import { XYZ } from 'ol/source.js';
import TileLayer from 'ol/layer/Tile';

let layers = [];

["2006", "2016", "2020"].forEach(year => {
    layers.push({
        name: `<a href='https://djlorenz.github.io/astronomy/lp${year}/'>djlorenz ${year}</a>`,
        layer:
        new TileLayer({
            source: new XYZ({
                attributions: `David Lorenz's <a href=http://djlorenz.github.io/astronomy/lp${year}/>Light Pollution Atlas ${year}</a>`,
                maxZoom: 6,
                tilePixelRatio:4,
                tileSize: (1024, 1024),
                transition: 0,
                url: `https://djlorenz.github.io/astronomy/lp${year}/overlay/tiles/tile_{z}_{x}_{y}.png`,
            }),
            visible:true,
            opacity: 0.5,
        }),
    });
});

export default {
    name: "Light Pollution",
    layers: layers,
    details: "For legend and more information, see <a href='https://www.cleardarksky.com/'>cleardarksky.com</a>.",
};
