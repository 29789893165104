import Minnesota from './Minnesota.geojson?url';
import Colorado from './Colorado.geojson?url';
import Iowa from './Iowa.geojson?url';
import Indiana from './Indiana.geojson?url';
import Kansas from './Kansas.geojson?url';
import Massachusetts from './Massachusetts.geojson?url';
import Nebraska from './Nebraska.geojson?url';

export default {
    Minnesota: Minnesota,
    Colorado: Colorado,
    Iowa: Iowa,
    Indiana: Indiana,
    Kansas: Kansas,
    Massachusetts: Massachusetts,
    Nebraska: Nebraska,
};
