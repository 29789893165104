import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style, Stroke} from 'ol/style.js';

import url from './data.geojson?url'; // TODO: remove `?url`?

const colors = '135, 0, 226'; // Käthe says lavender

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        url: url,
        format: new GeoJSON,
    }),
    style: function(feature, resolution){
        return new Style({
            stroke: new Stroke({
                color: `rgba(${colors},${Math.min(1, Math.pow(resolution/10, 1/4))})`,
                width: 10/Math.pow(resolution, 1/4),
            })
        });
    },
});

export default vectorLayer;
