import { XYZ } from 'ol/source.js';
import TileLayer from 'ol/layer/Tile';

// from https://stackoverflow.com/q/32454234
var quadkey = function (x, y, z) {
    var quadKey = [];
    for (var i = z; i > 0; i--) {
        var digit = '0';
        var mask = 1 << (i - 1);
        if ((x & mask) != 0) {
            digit++;
        }
        if ((y & mask) != 0) {
            digit++;
            digit++;
        }
        quadKey.push(digit);
    }
    return quadKey.join('');
};

const cellular_layers = {
    name: "Cellular Providers",
    layers: [
        // {
        //     name: "Verizon",
        //     // enabled: true,
        //     layer: new VectorTileLayer({
        //         source: new VectorTileSource({
        //             format: new MVT(),
        //             // https://api.mapbox.com/v4/gismaps.3knn09ds,gismaps.2x81tn8t,mapbox.mapbox-streets-v8,gismaps.aq7mk519,mapbox.country-boundaries-v1,gismaps.cbihlc9h,gismaps.7is2087g,gismaps.8hjkv530/7/31/46.vector.pbf
        //             // url: 'https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer/tile/{z}/{y}/{x}.pbf',
        //             url: 'https://api.mapbox.com/v4/gismaps.3knn09ds,gismaps.2x81tn8t,mapbox.mapbox-streets-v8,gismaps.aq7mk519,mapbox.country-boundaries-v1,gismaps.cbihlc9h,gismaps.7is2087g,gismaps.8hjkv530/{z}/{y}/{x}.pbf'
        //         }),
        //     }),
        // },
        {
            name: "AT&T Domestic",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0];
                        var x = tileCoord[1];
                        var y = tileCoord[2];
                        return "https://geolink-igw.cloud.att.com/mapservice/quadkeytile?layer=attDomestic&quadkey=" + quadkey(x, y, z);
                    },
                }),
            }),
        },
        {
            name: "AT&T Prepaid",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0];
                        var x = tileCoord[1];
                        var y = tileCoord[2];
                        return "https://geolink-igw.cloud.att.com/mapservice/quadkeytile?layer=attPrepaid&quadkey=" + quadkey(x, y, z);
                    },
                }),
            }),
        },
        {
            name: "AT&T International",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0];
                        var x = tileCoord[1];
                        var y = tileCoord[2];
                        return "https://geolink-igw.cloud.att.com/mapservice/quadkeytile?layer=attIntl&quadkey=" + quadkey(x, y, z);
                    },
                }),
            }),
        },
        {
            name: "Sprint 5G? (may also be T-Mo now); see <a href='https://coverage.sprint.com/IMPACT.jsp'>Sprint</a> for legends",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    // url: 'https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_5G/{z}/{x}:{y}/tile.png'
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0]+1;
                        var x = tileCoord[1]+1;
                        var y = tileCoord[2]+1;
                        return `https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_5G/${z}/${x}:${y}/tile.png`;
                    },
                }),
            }),
        },
        {
            name: "Sprint LTE? (may also be T-Mo now)",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    // url: 'https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_lte/{z}/{x}:{y}/tile.png'
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0]+1;
                        var x = tileCoord[1]+1;
                        var y = tileCoord[2]+1;
                        return `https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_lte/${z}/${x}:${y}/tile.png`;
                    },
                }),
            }),
        },
        {
            name: "Sprint non-LTE? (may also be T-Mo now)",
            layer: new TileLayer({
                opacity: 0.5,
                source: new XYZ({
                    // url: 'https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_5G/{z}/{x}:{y}/tile.png'
                    tileUrlFunction: function (tileCoord, pixelRatio, projection) {
                        var z = tileCoord[0]+1;
                        var x = tileCoord[1]+1;
                        var y = tileCoord[2]+1;
                        return `https://coverage.sprint.com/MapTile/5GDataMap/Tiles/data_xp_3G/${z}/${x}:${y}/tile.png`;
                    },
                }),
            }),
        },
    ],
};

export default cellular_layers;
