import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';

import {Style, Stroke} from 'ol/style.js';

import data from './routes.js';

const trails = {
    name: "Minnesota Adventure Trails",
    details: `<a href="https://minnesotaadventuretrails.com/">https://minnesotaadventuretrails.com/</a>`,
    layers: [],
};

for (let [chain, url] of Object.entries(data)) {
    const colors = '0,0,0';
    const vectorLayer = new VectorLayer({
        source: new VectorSource({
            url: url,
            format: new GeoJSON,
        }),
        style: function(feature, resolution){
            return new Style({
                stroke: new Stroke({
                    color: `rgba(${colors},${Math.min(1, Math.pow(resolution/10, 1/4))})`,
                    width: 10/Math.pow(resolution, 1/4),
                })
            });
        },
    });

    trails.layers.push({
        name: chain,
        layer: vectorLayer,
    });
}

trails.layers.sort((a, b) => a.name > b.name ? 1 : -1); // Names are always unique

export default trails;
