import VectorLayer from 'ol/layer/Vector';
import {Vector as VectorSource} from 'ol/source.js';

import {Style} from 'ol/style.js';
import Icon from 'ol/style/Icon.js';

import pinURL from '/layers/chandler/pin.svg?url'; // TODO: remove `?url`?
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { fromLonLat } from 'ol/proj';

const res = await fetch("https://whereis.chandlerswift.com/api/0/last");
const locs = await res.json();
const loc = locs[0];

let feature = new Feature({
    geometry: new Point(fromLonLat([loc.lon, loc.lat])),
    ...loc
});

const vectorLayer = new VectorLayer({
    source: new VectorSource({
        features: [
            feature,
        ]
    }),
    style: new Style({
        image: new Icon({
            anchor: [0.5, 1],
            src: pinURL,
        }),
    }),
});

setInterval(async function(){
    const res = await fetch("https://whereis.chandlerswift.com/api/0/last");
    const locs = await res.json();
    const loc = locs[0];
    feature.setProperties(loc); // TODO: this won't remove a property if it was in a previous response but not this one
    feature.getGeometry().setCoordinates(fromLonLat([loc.lon, loc.lat]));
}, 10 * 1000);

export default vectorLayer;
